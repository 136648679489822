#top-nav {
    position: sticky;
    top: 0px;
    width: 100%;
    z-index: 10;
    background-color: rgba(255, 123, 0, 0.95);
}

.nav-menu {
}

.main-content-frame {
    /* anything needed? */
}

.title-block {
    margin: 1rem 0rem;
}

.banner-home {
    background-image: url("https://mskiesstorage.blob.core.windows.net/www-images/marmalade_strip.jpg");
}

.banner-gallery {
    background-image: url("https://mskiesstorage.blob.core.windows.net/www-images/marmalade_jars.jpg");
}

.banner-testimonials {
    background-image: url("https://mskiesstorage.blob.core.windows.net/www-images/greek_salad.jpg");
}

.banner-contact {
    background-image: url("https://mskiesstorage.blob.core.windows.net/www-images/smoked_salmon.jpg");
}

  