.banner-image {
    background-image: url("https://mskiesstorage.blob.core.windows.net/www-images/three_marmalade_jars.jpg");
    background-attachment: fixed;
    background-position-x: center;
    /* Maybe try a parallax scrolling effect */

    background-repeat: no-repeat;
    background-size: cover;

    width: 100%;
    height: 35vh;
}

.banner-image {
    min-height: 200px;
}

@media (min-width: 576px) {
    .banner-image {
        min-height: 200px
    }
}

@media (min-width: 768px) {
    .banner-image {
        min-height: 200px
    }
}

@media (min-width: 992px) {
    .banner-image {
        min-height: 250px
    }
}

@media (min-width: 1200px) {
    .banner-image {
        min-height: 300px
    }
}

@media screen and (max-device-width: 1024px) {
    .banner-image {
        background-attachment: scroll
    }
}